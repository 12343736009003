import React, { useRef, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
import cx from "classnames"
import Layout from "src/components/layout.jsx"
import { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { loadStripe } from "@stripe/stripe-js"
import SEO from "src/components/seo.jsx"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import jsonResult from "src/helpers/json-result.js"
import Navbar from "src/components/navbar.jsx"
import styles from "src/styles/pages/sport.module.scss"
import sectionStyles from "src/styles/components/section.module.scss"
import SportLogo from "src/components/sport-logo"
import Jumbotron from "src/components/jumbotron"

let stripe
loadStripe(process.env.GATSBY_STRIPE_API_KEY).then(
  stripeReady => (stripe = stripeReady)
)

const LOGIN_URL = `${process.env.GATSBY_API_URL}/authenticate/`
const REGISTER_URL = `${process.env.GATSBY_API_URL}/1/user/create/`
const PAYMENT_SESSION_URL = `${process.env.GATSBY_API_URL}/website/stripe/session/`

const ERR_MSG_REQUIRED = "Dieses Feld ist ein Pflichtfeld."
const ERR_MSG_TOO_SHORT = "Eingabe zu kurz"
const ERR_MSG_TOO_LONG = "Eingabe zu lang"
const ERR_MSG_INVALID_EMAIL = "Ungültige Email"

const LoginBillingFormSchemaShape = {
  username: Yup.string()
    .min(2, ERR_MSG_TOO_SHORT)
    .max(30, ERR_MSG_TOO_LONG)
    .trim()
    .test(
      "at least one of email or username required",
      "Either a username or an email must be entered",
      function() {
        return !!(this.parent.username || this.parent.email)
      }
    ),
  email: Yup.string()
    .email(ERR_MSG_INVALID_EMAIL)
    .trim(),
  password: Yup.string()
    .min(6, ERR_MSG_TOO_SHORT)
    .max(30, ERR_MSG_TOO_LONG)
    .trim()
    .required(ERR_MSG_REQUIRED),
  terms: Yup.boolean()
    .equals([true])
    .required(ERR_MSG_REQUIRED),
}

const LoginBillingFormSchema = Yup.object().shape(LoginBillingFormSchemaShape)

const RegisterBillingFormSchemaShape = {
  ...LoginBillingFormSchemaShape,
  username: undefined,
  email: Yup.string()
    .email(ERR_MSG_INVALID_EMAIL)
    .trim()
    .required(ERR_MSG_REQUIRED),
}

const RegisterBillingFormSchema = Yup.object().shape(
  RegisterBillingFormSchemaShape
)

export default function Sport() {
  const [newAccount, setNewAccount] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [formError, setFormError] = useState("")
  const [plainTextPasswords, setPlainTextPasswords] = useState(false)
  const registrationFormRef = useRef()
  const BillingFormSchema = newAccount
    ? RegisterBillingFormSchema
    : LoginBillingFormSchema

  useEffect(() => {
    setIsSuccess(window.location.hash === "#success")
  }, [])

  useEffect(() => {
    if (registrationFormRef.current) {
      registrationFormRef.current.setErrors({})
      setFormError("")
    }
  }, [newAccount])

  const handleCheckout = (values, { setSubmitting }) => {
    setSubmitting(true)

    const url = newAccount ? REGISTER_URL : LOGIN_URL
    const sendValues = {
      ...values,
      ...(newAccount && { username: uuidv4() }),
      ...(!newAccount && !values.username && { username: values.email }),
      registration_code: process.env.GATSBY_SPORT_REGISTRATION_CODE,
    }

    const error400msg = newAccount
      ? "Diese Email wird bereits von einem bestehenden Account verwendet."
      : "Falsche Logindaten"

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sendValues),
    })
      .then(jsonResult)
      .then(({ token }) =>
        fetch(PAYMENT_SESSION_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            priceId: process.env.GATSBY_SPORT_PRICE_ID,
            email: values.email,
          }),
        })
      )
      .then(jsonResult)
      .then(({ session_id }) =>
        stripe.redirectToCheckout({ sessionId: session_id })
      )
      .catch(response => {
        setFormError(response.status === 400 ? error400msg : "Fehler")
        setSubmitting(false)
      })
  }

  const renderRegisterForm = () => (
    <Formik
      innerRef={registrationFormRef}
      initialValues={{
        username: "",
        email: "",
        password: "",
        terms: false,
        newsletter: false,
      }}
      validationSchema={BillingFormSchema}
      onSubmit={handleCheckout}
      isInitialValid={false}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12}>
              <h2 className={cx(styles.green, styles.formTitle)}>
                Bestellformular
              </h2>
            </Grid>
            {!newAccount && (
              <Grid item xs={12} className={styles.inputRow}>
                <p className={styles.inputLabel}>
                  Benutzername (Angabe nur nötig, wenn bisher ohne E-Mail
                  registriert.)
                </p>
                <Field type="text" name="username" />
                <ErrorMessage
                  name="username"
                  className={cx(styles.errorText, "error")}
                  component="div"
                />
              </Grid>
            )}
            <Grid item xs={12} className={styles.inputRow}>
              <p className={styles.inputLabel}>Email</p>
              <Field type="email" name="email" />
              <ErrorMessage
                name="email"
                className={cx(styles.errorText, "error")}
                component="div"
              />
            </Grid>
            <Grid item xs={12} className={styles.inputRow}>
              <p className={styles.inputLabel}>Passwort</p>
              <div className={styles.inputRow}>
                <Field
                  type={plainTextPasswords ? "text" : "password"}
                  name="password"
                />
                <button
                  className={styles.inputToggle}
                  type="button"
                  onClick={() => setPlainTextPasswords(v => !v)}
                >
                  {plainTextPasswords ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </button>
              </div>
              <ErrorMessage
                name="password"
                className={cx(styles.errorText, "error")}
                component="div"
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={1} className={styles.checkboxContainer}>
              <Field type="checkbox" name="terms" />
            </Grid>
            <Grid item xs={11}>
              <label className="noselect">
                {"Hiermit bestätige ich, dass ich die "}
                <a href="/healthprerequisites" target="_blank">
                  Gesundheitsvorraussetzungen
                </a>
                {" ERFÜLLE und stimme den "}
                <a href="/agb" target="_blank">
                  AGB
                </a>
                {", "}
                <a href="/privacy" target="_blank">
                  Datenschutzbestimmungen
                </a>
                {" zu."}
              </label>
            </Grid>
            {newAccount && (
              <>
                <Grid item xs={1} className={styles.checkboxContainer}>
                  <Field type="checkbox" name="newsletter" />
                </Grid>
                <Grid item xs={11}>
                  <label className="noselect">
                    Ich möchte den Mindance Newsletter mit regelmäßigen Infos
                    zur mentalen Gesundheit und Mentaltraining abonnieren.
                  </label>
                </Grid>
              </>
            )}
            <Grid xs={12}>
              {<p className={cx(styles.formError, "error")}>{formError}</p>}
            </Grid>
            <Grid xs={12}>
              <button
                type="submit"
                disabled={!isValid || isSubmitting}
                className={isSubmitting && "progress"}
              >
                Zahlungspflichtig für 59,88€ bestellen
              </button>
            </Grid>
            <Grid xs={12}>
              <p className={styles.formFooterText}>
                Mit dieser Bestellung erwirbst Du einen Zugang zur
                Mindance-Sport App.
              </p>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )

  const renderForm = () => (
    <div className={cx(styles.content, styles.formSection)} id={"Formik"}>
      <div className={styles.registerForm}>
        <div className={styles.registerFormTabs}>
          <Grid container>
            <Grid
              item
              xs={6}
              onClick={() => setNewAccount(true)}
              className={cx(styles.registerFormTab, {
                [styles.activeTab]: newAccount,
                noselect: true,
              })}
            >
              <p>Ich bin neu bei Mindance</p>
            </Grid>
            <Grid
              item
              xs={6}
              onClick={() => setNewAccount(false)}
              className={cx(styles.registerFormTab, {
                [styles.activeTab]: !newAccount,
                noselect: true,
              })}
            >
              <p>Ich habe bereits einen Account</p>
            </Grid>
          </Grid>
        </div>
        {renderRegisterForm()}
      </div>
    </div>
  )

  const renderSuccess = () => (
    <div className={cx(styles.content)}>
      <div className={styles.success}>
        <h4 className={styles.green}>
          Glückwunsch, Deine Bestellung war erfolgreich!
        </h4>
        <p>
          <b>
            Du erhältst nun eine Mail mit Deiner Rechnung sowie eine weitere
            Mail mit der Bestellbestätigung und den nächsten Schritten.
          </b>
        </p>
        <p>
          Falls Du keine Bestellbestätigung oder Rechnung erhalten haben
          solltest, schaue in Deinem Spam Ordner nach oder kontaktiere uns unter{" "}
          <a href="mailto:support@mindance.de">support@mindance.de</a>.
        </p>
      </div>
    </div>
  )

  return (
    <Layout className={styles.root}>
      <Navbar />
      <Jumbotron
        section={{
          title: "Spitzenleistung beginnt im Kopf",
          description:
            "Du möchtest ebenfalls mit Mindance trainieren, bist aber vereinslos oder hast keine sportpsychologische Betreuung? Auch für dich haben wir ein passendes Angebot zusammengestellt, um dich mental auf die nächsten sportlichen Herausforderungen vorzubereiten.",
          cta_text: "Angebot anfordern",
          cta_link: "#Formik",
        }}
        backgroundImage="/assets/img/sport/jumbotron-bg.png"
      />
      <SEO
        title="Sport Page"
        description="Sportler erhalten jetzt Zugriff auf das Angebot von Mindance. Fordern Sie dazu Ihr Angebot für die Mindance-App an, um alle Vorteile zu nutzen."
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
      />
      <div
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.section
        )}
      >
        <Grid container spacing={0} className={styles.teambg}>
          <Grid item xs={24} md={6}>
            <img src="/assets/img/sport/sport-app.png" />
          </Grid>
          <Grid item xs={12} md={6}>
            <h1>Mindance Sport Training</h1>
            <p className={styles.featureDescription}>
              Du erhältst Zugriff zu unser wissenschaftlichen Datenbank an
              fundierten sportpsychologischen Trainingstechniken. Egal, ob du
              nervös vor einem anstehenden Wettkampf bist, oder aber einfach
              eine Unterstützung zur Regeneration benötigst - wir haben die
              passende Übung für dich.
            </p>
            <h3>Mental Prep</h3>
            <p className={styles.featureDescription}>
              Übung zur mentalen Vorbereitung auf das nächste Training oder
              einen anstehenden Wettkampf.
            </p>
            <h3>Regeneration</h3>
            <p className={styles.featureDescription}>
              Übungen, die dir dabei helfen, zu entspannen und deine Batterien
              wieder aufzuladen.
            </p>
            <h3>Fokus</h3>
            <p className={styles.featureDescription}>
              Übungen, die dir dabei helfen, dich auf wesentliche Dinge zur
              richtigen Zeit zu konzentrieren.
            </p>
            <h3>Selbstbewusstsein</h3>
            <p className={styles.featureDescription}>
              Übungen zum Beleuchten deiner eigenen Stärken und Fähigkeiten.
            </p>
            <h3>Wettkampfangst</h3>
            <p className={styles.featureDescription}>
              Übungen, die dir dabei helfen, Strategien gegen Wettkampfangst zu
              entwickeln.
            </p>
            <br />
            <br />
            <br />
            <a href={"#Formik"}>
              <button>{"Jahr 59,88€ | Jetzt bestellen"}</button>
            </a>
          </Grid>
        </Grid>
      </div>
      <SportLogo />
      <div
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.profileSection
        )}
      >
        <Grid container spacing={2} className={styles.greenbg}>
          <Grid item xs={12}>
            <h4>
              Du bist dir nicht sicher, ob Mindance das Richtige ist? Nimm
              Kontakt zu uns auf und wir klären alle deine offenen Fragen!
            </h4>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={styles.profile}>
              <img
                className={styles.columnImage}
                src="/assets/img/sport/lukas.png"
              />
              <h5>Lukas Stenzel</h5>
              <span>M.Sc. Psychologie</span>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              Lukas Stenzel ist ausgebildeter Sportpsychologe (asp-Curriculum),
              promoviert berufsbegleitend im Bereich der angewandten
              Sportpsychologie an der Universität Halle und ist für die
              wissenschaftlich fundierten Inhalte bei Mindance verantwortlich.
            </p>
            <p>
              <a href="mailto:lukas.stenzel@mindance.de">
                lukas.stenzel@mindance.de
              </a>
            </p>
          </Grid>
        </Grid>
      </div>
      {isSuccess ? renderSuccess() : renderForm()}
    </Layout>
  )
}
